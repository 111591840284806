import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  memo,
} from 'react';
import styles from './InputPassword.module.scss';
import classNames from 'classnames';
import { useTranslate } from 'utils/translator';
import { Icons } from 'assets/icons';
// eslint-disable-next-line import/no-cycle
import { InputNext, Icon } from 'ui/basic';

const Config = {
  criteria: [
    'pwrequirement_form_pwhint_uppercase',
    'pwrequirement_form_pwhint_lowercase',
    'pwrequirement_form_pwhint_number',
    'pwrequirement_form_pwhint_specialchar',
    'pwrequirement_form_pwhint_length',
  ],
};


const InputPassword = memo((props) => {
  const {
    value = '',
    placeHolder = '',
    hint = '',
    error = '',
    disabled,
    showCriteria,
    hideCriteriaOnBlur = true,
    onFocus,
    onInitialized,
  } = props;

  const translate = useTranslate();
  const [ inputValue, setInputValue ] = useState(props.value || '');
  const [ inputCheckResults, setInputCheckResult ] = useState([ false, false, false, false, false ]);
  const [ isFocused, setFocus ] = useState(false);
  const ref = useRef();

  const checkInput = (str) => {
    const results = [ ...inputCheckResults ];
    for (let i = 0; i < inputCheckResults.length; i += 1) {
      switch (i) {
        case 0:
          // Check on uppercase
          results[i] = /[A-Z]/.test(str);
          break;
        case 1:
          // Check on lowercase
          results[i] = /[a-z]/.test(str);
          break;
        case 2:
          // Check on number
          results[i] = /[0-9]/.test(str);
          break;
        case 3: {
          // Check on special char
          // eslint-disable-next-line prefer-regex-literals
          const specialReg = new RegExp('^(?=.*[!@#$%^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])');
          results[i] = specialReg.test(str);
          break;
        }
        case 4:
          // Check on length
          results[i] = str.length >= 8;
          break;
        default:
      }
    }
    const correct = results.indexOf(false) === -1;
    if (props.onInputChanged) {
      props.onInputChanged(str, correct);
    }
    setInputCheckResult([ ...results ]);
  };
  const handleChange = (newValue) => {
    checkInput(newValue);
    if (props.onChange) {
      props.onChange(ref.current, newValue);
    }
  };

  const initialize = useCallback(() => {
    if (ref?.current) {
      onInitialized?.(ref.current);
    }
    setInputValue(value);
  }, [ onInitialized, value ]);

  useEffect(() => {
    setInputValue(value);
  }, [ value ]);

  useEffect(() => {
    initialize();
  }, [ initialize ]);

  return (
    <div className={classNames(styles.inputPassword, props.className)}>
      <InputNext
        type='password'
        disabled={disabled}
        value={inputValue}
        label={placeHolder}
        hint={hint}
        errorMessage={error}
        onChange={handleChange}
        onFocus={(event) => {
          setFocus(true);
          onFocus?.(event);
        }}
        onBlur={() => {
          if (hideCriteriaOnBlur) {
            setFocus(false);
          }
        }}
        onKeyUp={(event) => {
          // preventive measure so form elements in Dialogs / Assessments etc. don't trigger page navigations
          if (event.key !== 'Enter') {
            event.stopPropagation();
          }
        }}
      />

      { (showCriteria && isFocused) && (
        <div className={classNames(styles.criteria)}>
          { Config.criteria.map((criterium, index) => {
            const correct = inputCheckResults[index];
            return (
              <div
                className={classNames(styles.criterium, { [styles.correct]: correct })}
                key={criterium}
              >
                { translate(criterium) }
                { !correct ? null : (
                  <div className={styles.icon}>
                    <Icon icon={Icons.CheckSmall} />
                  </div>
                ) }
              </div>
            );
          }) }
        </div>
      ) }
    </div>
  );
});

export default InputPassword;
