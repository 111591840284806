import React, { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

// UTILS
import { useTranslate } from 'utils/translator';
import { useBreakpoint } from 'utils/hooks';
import { DIMENSION_BLOCKS, getResistanceText, getSubHeaderShort } from 'utils/nineLevels';
import { ASSESSMENT_TYPES } from 'utils/configuration/const/assessment-types';
import { filterHiddenResults } from 'utils/assessment';

import { initSubmitContentFeedback, submitContentFeedback } from 'store/actions';

import { ReactComponent as InfoBlue } from 'assets/icons/icn_info_blue.svg';

import RmpResultSkeleton from 'ui/molecules/RmpResult/RmpResultSkeleton';
import {
  InfoTitle, Button, Modal, ShowResultsButton,
} from 'ui/basic';
// eslint-disable-next-line import/no-cycle
import NineLevelsReport from './NineLevelsReport';
import { DownloadResultButton } from '../DownloadResultButton';

import NineLevelsDiagram from './NineLevelsDiagram/NineLevelsDiagram';
import styles from './NineLevelsResult.module.scss';


const NineLevelsResult = (props) => {
  const {
    results = [],
    signatureValues,
    forReport,
    showReportButton = true,
    requestFeedback = true,
    footerText,
    noDataText,
    showExportIcon,
    showInfoTitle = true,
    exportView,
    onExport = () => {},
    userId,
    managerControlled,
    sharedWithUser,
  } = props;

  const translate = useTranslate();
  const dispatch = useDispatch();
  const bp = useBreakpoint();

  // STATE
  const [ currentDimensionId, setCurrentDimensionId ] = useState();
  const [ showModal, setShowModal ] = useState();
  const [ modalHeader, setModalHeader ] = useState();
  const [ modalSubHeader, setModalSubHeader ] = useState();
  const [ modalScrollable, setModalScrollable ] = useState();
  const [ modalContent, setModalContent ] = useState();
  const [ modalPrimaryButtonTitle, setModalPrimaryButtonTitle ] = useState();
  const [ showModalFeedback, setShowModalFeedBack ] = useState();
  const [ modalData, setModalData ] = useState();

  const [ showReport, setShowReport ] = useState(false);

  const resultsFiltered = useMemo(() => filterHiddenResults(results), [ results ]);
  const showRange = resultsFiltered?.[0] && Array.isArray(resultsFiltered[0].range);

  // SELECTORS
  const nineLevelsAssessment = useSelector((state) => state.assessments.table[ASSESSMENT_TYPES.NINE_LEVELS]);
  const feedbackSubmittedSuccessfully = useSelector((state) => state?.survey?.success);

  // METHODS
  const getItemRes = (resultItem = {}, index = 0) => {
    const { result = [], range = [] } = resultItem;
    return result[index] ?? range[index];
  };

  const renderModalContent = () => {
    const { dimensionName, resultItem } = modalData;
    return (
      <div className={styles.modalContent}>
        <div className={styles.dimensionName}>{ dimensionName }</div>

        { /* DIAGRAM */ }
        <div className={styles.diagram}>
          <NineLevelsDiagram
            results={resultItem.result}
            color={resultItem.id.split('levels_')[1]}
          />
          <div className={styles.footer}>
            <span>
              { getItemRes(resultItem, 0) }
            </span>
            <span>
              { getItemRes(resultItem, 1) }
            </span>
          </div>
        </div>

        { /* DESCRIPTION */ }
        <div className={styles.description}>
          { translate(`${resultItem.id}_info_description`) }
        </div>

        { /* BLOCKS */ }
        <div className={styles.blocks}>
          { DIMENSION_BLOCKS.map((block) => (
            <div className={styles.block} key={block.label}>
              <div className={styles.label}>
                { translate(block.label) || block.altLabel }
              </div>
              <div className={styles.description}>
                { translate(block.description.replace('*', resultItem.id.split('_').pop()))
                  || block.altDescription }
              </div>
            </div>
          )) }

          <div className={styles.block}>
            <div className={styles.label}>
              { translate('9levels_report__level__resistance') }
            </div>
            <div className={styles.description}>
              { translate(getResistanceText(resultItem.result[0]), [
                '{{level}}', translate(`levels_results_label_${resultItem.id.split('_').pop()}`),
                '{{points}}', resultItem.result[0],
              ]) }
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Rerender modal content on resize
  useEffect(() => {
    if (!modalData?.dimensionName) {
      return;
    }

    setModalContent(renderModalContent());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ bp.isS, bp.isXs, modalData?.dimensionName ]);

  if (!resultsFiltered && (nineLevelsAssessment && nineLevelsAssessment.resultsPending === undefined)) {
    return <RmpResultSkeleton />;
  }

  return (
    <div className={classNames(styles.nineLevelsResult, { [styles.forReport]: forReport })}>
      <div id='container-id' className={styles.container}>
        <div className={styles.mainHeader}>
          { (exportView || !showInfoTitle)
            ? (
              <strong className='bluTypeLabel'>
                { translate('9levels_results_title') }
              </strong>
            ) : (
              <InfoTitle
                title={translate('9levels_results_title')}
                onClick={() => {
                  setModalHeader(translate('9levels_results_details_title'));
                  setModalContent(translate('9levels_results_details_description'));
                  setModalSubHeader(null);
                  setShowModalFeedBack(false);
                  setModalScrollable(false);
                  setModalPrimaryButtonTitle(null);
                  setShowModal(true);
                }}
              />
            ) }

          { (!exportView && showExportIcon && !nineLevelsAssessment.resultsPending && !noDataText) && (
            <DownloadResultButton onExport={onExport} />
          ) }
        </div>

        { noDataText && <div className={styles.noDataText}>{ noDataText }</div> }

        { !showRange && (nineLevelsAssessment && nineLevelsAssessment.resultsPending) && (
          <div className={styles.pendingText}>
            { translate('rmp_results_pending_copy') }
          </div>
        ) }

        { !noDataText && (
          <>
            { managerControlled && (
              <ShowResultsButton
                userId={userId}
                assessmentId={ASSESSMENT_TYPES.NINE_LEVELS}
                sharedWithUser={sharedWithUser}
              />
            ) }

            <div className={styles.rows}>
              { resultsFiltered.map((resultItem) => {
                const { range } = resultItem;
                if (range) {
                  range[0] = Math.floor(range[0].toFixed(1));
                  range[1] = Math.ceil(range[1].toFixed(1));
                }
                return (
                  <div className={styles.row} key={resultItem.id}>
                    <div className={styles.header}>
                      { translate(`levels_results_label_${resultItem.id.split('_').pop()}`) || resultItem.name }

                      { !exportView && (
                        <InfoBlue onClick={() => {
                          if (!showReportButton) {
                            setModalHeader(translate(`${resultItem.id}_info_title`));
                            setModalContent(translate(`${resultItem.id}_info_description`));

                            setShowModal(true);
                            return;
                          }

                          const dimensionName = translate(`levels_results_label_${resultItem.id.split('_').pop()}`)
                        || resultItem.name;
                          const color = resultItem.id.split('levels_')[1];

                          setCurrentDimensionId(resultItem.id);

                          setModalHeader(
                            <div className={styles.modalHeaderContent}>
                              { /* colored block on the left */ }
                              <div style={{
                                backgroundColor: styles[`color${color.substring(0, 1).toUpperCase()
                                  + color.substring(1, color.length)}1`],
                              }}
                              />
                              <span>{ translate(`${resultItem.id}_info_title`) }</span>
                            </div>,
                          );
                          setModalSubHeader(getSubHeaderShort(
                            dimensionName,
                            resultItem.result ? resultItem.result[1] : 0,
                          ));
                          setModalPrimaryButtonTitle(translate('go_to_report_lbl'));
                          setModalScrollable(true);
                          setShowModalFeedBack(true);

                          setModalData({ dimensionName, resultItem });

                          setShowModal(true);
                        }}
                        />
                      ) }
                    </div>

                    <div className={styles.diagram}>
                      <NineLevelsDiagram
                        range={range}
                        results={resultItem.result}
                        color={resultItem.id.split('levels_')[1]}
                      />
                    </div>

                    <div className={styles.footer}>
                      { (range && !resultItem.result)
                        ? (
                          <span style={{ marginLeft: 'auto' }}>
                            { `${range[0]} ${translate('to_lbl')} ${range[1]}` }
                          </span>
                        ) : (
                          <>
                            <span>
                              { getItemRes(resultItem, 0) }
                            </span>
                            <span>
                              { getItemRes(resultItem, 1) }
                            </span>
                          </>
                        ) }
                    </div>
                  </div>
                );
              }) }
            </div>

            { (!showRange && showReportButton
            && (nineLevelsAssessment && !nineLevelsAssessment.resultsPending) && !exportView)
            && (
              <div className={styles.showReport}>
                <Button
                  size='S'
                  looks='secondary'
                  onClick={() => setShowReport(true)}
                >
                  { translate('9levels_results_show_report_btn') }
                </Button>
              </div>
            ) }

            { footerText && (
              <div className={styles.footerText}>
                { footerText }
              </div>
            ) }

            <div
              className={styles.copyright}
              role='presentation'
              onClick={() => {
                setModalHeader(translate('9levels_ass_info_title_copyright'));
                setModalContent(translate('9levels_ass_info_description_copyright'));
                setModalSubHeader(null);
                setModalScrollable(false);
                setShowModalFeedBack(false);
                setModalPrimaryButtonTitle(null);

                setShowModal(true);
              }}
            >
              { translate('9levels_ass_copyrightlink') }
            </div>
          </>
        ) }

        { showModal && (
          <Modal
            header={modalHeader}
            subHeader={modalSubHeader}
            primaryButtonTitle={modalPrimaryButtonTitle}
            secondaryButtonTitle={translate('wellbeing_ind_result_info_modal_close_btn')}
            controlScrollbar={!forReport}
            onClose={() => {
              dispatch(initSubmitContentFeedback());
              setShowModal(false);
              setModalData();
            }}
            onConfirm={modalScrollable
              ? (() => {
                setShowModal(false);
                setModalData();
                setShowReport(true);
              })
              : (() => {
                setShowModal(false);
                setModalData();
              })}
            feedbackSubmitted={feedbackSubmittedSuccessfully}
            onFeedbackSubmit={(requestFeedback && showModalFeedback)
              ? ((feedback) => {
                dispatch(submitContentFeedback({
                  contentId: currentDimensionId,
                  contentType: 'recommendation',
                  helpful: feedback === 'Yes',
                }));
              })
              : null}
          >
            { modalContent }
          </Modal>
        ) }
      </div>

      { showReport && createPortal(
        <NineLevelsReport
          results={resultsFiltered}
          signatureValues={signatureValues}
          onClose={() => setShowReport(false)}
        />,
        document.body,
      ) }
    </div>
  );
};

export default NineLevelsResult;
